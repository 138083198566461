<template>
    <div class="table">
        <div class="flex">
            <div class="title">
                Pastors Information
            </div>

            <div class="search">
                <input type="text" placeholder="Search" @input="search()" v-model="text">
            </div>
        </div>

        <div class="head">
            <div class="item">Name</div>
            <div class="item">Group</div>
            <div class="item">State</div>
            <div class="item">Age</div>
            <div class="item">Phone Number</div>
            <div class="item">Last Known Location</div>
            <div class="item">Status</div>
        </div>
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <user v-for="(p, i) in pas" :m="p" :key="i"/>
        </div>
    </div>
</template>

<script>
import user from './user.vue'
export default {
    components: {
        user
    },
    props: ['pastors', 'loading'],
    watch: {
        loading() {
            this.pas = this.pastors
        }
    },
    data() {
        return {
            pas: [],
            text: ''
        }
    },
    methods: {
        search() {
            this.pas = this.pastors.filter(pastor => {
                let name = (pastor.surname + ' ' + pastor.firstname).toLowerCase()
                let r = name.search(this.text.toLowerCase()) == -1 ? false : true
                return r
            })
        }
    }
}
</script>

<style scoped>
    .table {
        margin-top: 30px;
        border-radius: 10px;
        overflow: hidden;
        background-color: white;
        box-shadow: 0px 0px 10px #ccc;
        padding-bottom: 20px;
        min-width: 1000px;
    }
    .flex {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--main);
    }
    .title {
        /* padding: 20px; */
        /* background-color: #aaa; */
        color: white;
        font-size: 18px;
        font-weight: 500;
    }
    .search {
        width: fit-content;
        margin-left: auto;
    }
    input {
        
        padding: 10px;
        /* border: 1px solid #ccc; */
        border: none;
        box-sizing: border-box;
        border-radius: 7px;
        width: 100%;
    }

    .head {
        display: grid;
        grid-template-columns: 25% 15% 10% 7% 15% 18% 10%;
        border-bottom: 2px solid var(--main);
        padding: 20px;
    }
    .item {
        font-weight: 500;
    }

    .loading {
        padding: 30px 0px;
        padding-top: 60px;
    }
    .spin {
        border: 2px solid var(--main);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border-top-color: transparent;
        animation: rot 1s infinite linear;
        margin: 0 auto;
    }
    @keyframes rot {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>