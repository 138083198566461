<template>
    <div class="admin">
        <headerLayout/>
        <div class="grid">
            <menuLayout class="web"/>
            <div class="main" v-if="ready">
                <router-view></router-view>
            </div>
            <mobile class="mobile"/>
        </div>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import menuLayout from '../components/menu.vue'
import mobile from '../components/mobile.vue'
import Localbase from 'localbase'
export default {
    components: {
        headerLayout, menuLayout, mobile
    },
    data() {
        return {
            db: new Localbase('bcgdata'),
            ready: false
        }
    },
    created() {
        this.db.collection('auth').doc({ id: 1 }).get().then(data => {
            if (data == undefined) {
                this.$router.push('/login')
            } else {
                this.$http.defaults.headers.common['Auth'] = data.key
                setTimeout(() => {
                    this.ready = true  
                }, 200);
            }
        })
    }
}
</script>

<style scoped>
    .grid {
        display: grid;
        grid-template-columns: 250px auto;
        height: calc(100vh - 78px);
    }
    .main {
        height: 100%;
        overflow: auto;
        background-color: #edeef0;
        border-top: 1px solid #ccc;
    }
    .mobile {
        display: none;
    }
    
    @media screen and (max-width: 750px) {
        .grid {
            grid-template-columns: auto;
        }
        .web {
            display: none;
        }
        .mobile {
            display: grid;
        }
        .main {
            height: calc( 100vh - 128px);
        }
    }
</style>