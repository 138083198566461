<template>
    <div class="add">
        <div class="title">Add a new pastor</div>
        <div class="form">
            <div class="t">BCG DATABASE CAPTURE</div>
            <div class="upload" @click="upload()">
                <div class="up_t" v-if="image == ''">Click to select a picture</div>
                <img :src="image" alt="" v-else>
            </div>
            <div class="input">
                <div class="label" v-if="status != ''">Status</div>
                <select v-model="status">
                    <option value="" disabled selected>Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Gaffed">Gaffed</option>
                    <option value="Excommunicated">Excommunicated</option>
                    <option value="Deceased">Deceased</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="group != ''">Church Group</div>
                <select v-model="group">
                    <option value="" disabled selected>Church Group</option>
                    <option v-for="g in groups" :value="g" :key="g">{{g}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="firstname != ''">Firstname</div>
                <input type="text" placeholder="Enter Firstname" v-model="firstname">
            </div>
            <div class="input">
                <div class="label" v-if="othername != ''">Other Names</div>
                <input type="text" placeholder="Enter Other Names" v-model="othername">
            </div>
            <div class="input">
                <div class="label" v-if="surname != ''">Surname</div>
                <input type="text" placeholder="Enter Surname" v-model="surname">
            </div>
            <div class="input">
                <div class="label" v-if="gender != ''">Gender</div>
                <select v-model="gender">
                    <option value="" disabled selected>Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="marital != ''">Marital Status</div>
                <select v-model="marital">
                    <option value="" disabled selected> Marital Status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Seperated">Seperated</option>
                    <option value="Widowed">Widowed</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="spouse_name != ''">Spouse Name</div>
                <input type="text" placeholder="Enter Spouse Name" v-model="spouse_name">
            </div>
            <div class="input">
                <div class="label" v-if="children != ''">Children</div>
                <input type="text" placeholder="Children" v-model="children">
            </div>
            <div class="input">
                <div class="label" v-if="address != ''">Address</div>
                <input type="text" placeholder="Enter Address" v-model="address">
            </div>
            <div class="input">
                <div class="label" v-if="city != ''">City</div>
                <input type="text" placeholder="Enter City" v-model="city">
            </div>
            <div class="input">
                <div class="label" v-if="state != ''">State</div>
                <input type="text" placeholder="Enter State" v-model="state">
            </div>
            <div class="input">
                <div class="label" v-if="postal != ''">Postal Code</div>
                <input type="text" placeholder="Enter Postal Code" v-model="postal">
            </div>
            <div class="input">
                <div class="label" v-if="country != ''">Country</div>
                <input type="text" placeholder="Enter Country" v-model="country">
            </div>
            <div class="input">
                <div class="label" v-if="email != ''">Email</div>
                <input type="text" placeholder="Enter Email" v-model="email">
            </div>
            <div class="input">
                <div class="label" v-if="home_phone != ''">Phone 1</div>
                <input type="text" placeholder="Enter Phone 1" v-model="home_phone">
            </div>
            <div class="input">
                <div class="label" v-if="mobile_phone != ''">Phone 2</div>
                <input type="text" placeholder="Enter Phone 2" v-model="mobile_phone">
            </div>
            <div class="input">
                <div class="label" v-if="altar_phone != ''">Phone 3</div>
                <input type="text" placeholder="Enter Phone 3" v-model="altar_phone">
            </div>
            <div class="input">
                <div class="label" v-if="fax != ''">Phone 4</div>
                <input type="text" placeholder="Enter Phone 4" v-model="fax">
            </div>
            <div class="input">
                <div class="label">Birthday</div>
                <input type="date" placeholder="Enter Birthday" v-model="b">
            </div>
            <div class="input">
                <div class="label" v-if="nationality != ''">Nationality</div>
                <input type="text" placeholder="Enter Nationality" v-model="nationality">
            </div>
            <div class="input">
                <div class="label" v-if="village != ''">Village/Town</div>
                <input type="text" placeholder="Enter Village/Town" v-model="village">
            </div>
            <div class="input">
                <div class="label" v-if="lga != ''">LGA/Country</div>
                <input type="text" placeholder="Enter LGA/Country" v-model="lga">
            </div>
            <div class="input">
                <div class="label" v-if="s_o_o != ''">State Of Origin</div>
                <input type="text" placeholder="Enter State Of Origin" v-model="s_o_o">
            </div>
            <div class="input">
                <div class="label" v-if="location != ''">Last Known Location</div>
                <select v-model="location">
                    <option value="" disabled selected>Last Known Location</option>
                    <option v-for="l in locations" :key="l" :value="l">{{l}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label">Date Joined BCG</div>
                <input type="date" placeholder="Enter Present Location" v-model="j">
            </div>
            <div class="input">
                <div class="label" v-if="emer_name != ''">Emergency Contact Name</div>
                <input type="text" placeholder="Enter Emergency Name" v-model="emer_name">
            </div>
            <div class="input">
                <div class="label" v-if="emer_phone != ''">Emergency Contact Number</div>
                <input type="text" placeholder="Enter Emergency Contact Number" v-model="emer_phone">
            </div>
            <div class="t">Bank Details</div>
            <div class="input">
                <div class="label" v-if="bank_acct_name != ''">Bank Account Name</div>
                <input type="text" placeholder="Enter Bank Account Name" v-model="bank_acct_name">
            </div>
            <div class="input">
                <div class="label" v-if="acct_no != ''">Account Number</div>
                <input type="number" placeholder="Enter Account Number" v-model="acct_no">
            </div>
            <div class="input">
                <div class="label" v-if="bank_name != ''">Bank Name</div>
                <input type="text" placeholder="Enter Bank Name" v-model="bank_name">
            </div>
            <div class="input">
                <div class="label" v-if="remarks != ''">Remarks</div>
                <textarea placeholder="Enter Remarks" v-model="remarks"></textarea>
            </div>
            <loader :height="'37px'" v-if="loading"/>
            <div class="grid" v-else>
                <div class="btn" @click="add('ok')">Save</div>
                <div class="btn" @click="add('draft')">Save as draft</div>
            </div>
            <input type="file" accept="image/*" class="hidden" ref="image" @change="handleDp($event)"/>
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import loader from '../components/loading.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            is_image: false,
            image: '',
            pic: null,
            status: '',
            group: '',
            groups: [
                'ALTAR PASTORS', 'INNER CIRCLE SAINT', 'SAINTS OF HONOUR', 'RUTH SISTERS', 'LADIDI WOMEN',
                'MIRACLE PASTORS', 'SOLIDER PASTORS', 'INTERCESSORY PASTORS', 'SERIDYL', 'INGLOMICO'
            ],
            surname: '',
            firstname: '',
            othername: '',
            gender: '',
            marital: '',
            spouse_name: '',
            children: '',
            address: '',
            city: '',
            state: '',
            postal: '',
            country: '',
            email: '',
            home_phone: '',
            mobile_phone: '',
            altar_phone: '',
            fax: '',
            birthday: '', b: '',
            nationality: '',
            village: '',
            lga: '',
            s_o_o: '',
            location: '',
            locations: ['Lagos', 'Amukpe (Delta)', 'Opi (Enugu)', 'Owerri (Imo)'],
            joined: '', j: '',
            emer_name: '',
            emer_phone: '',
            bank_acct_name: '',
            acct_no: '',
            bank_name: '',
            remarks: '',
            loading: false
        }
    },
    watch: {
        b(val) {
            var date = new Date(val);
            this.birthday = date.getTime() / 1000;
            console.log(this.birthday);
        },
        j(val) {
            var date = new Date(val);
            this.joined = date.getTime() / 1000;
            console.log(this.joined);
        }
    },
    methods: {
        upload() {
            this.$refs['image'].click()
        },
        handleDp(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result
                    this.is_image = true
                }
                
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 1000,
                    useWebWorker: true
                }
                imageCompression(picture, options).then( res => {
                    // console.log(res);
                    this.pic = new File([res], picture.name)
                })

            }
        },
        add (has) {
            this.loading = true

            if (this.birthday == '') { this.birthday = new Date('0000').getTime() / 1000 }
            if (this.joined == '') { this.joined = new Date('0000').getTime() / 1000 }

            let data = this.$data

            let k
            let form = new FormData()
            for (k in data) {
                form.append(k, this[k])
            }
            form.delete('image')
            form.delete('groups')
            form.delete('loading')
            form.delete('b')
            form.delete('j')
            form.delete('locations')
            if (this.is_image) {
                let pic = this.pic
                console.log(pic);
                form.append('picture', pic)
            }

            form.append('save', has)

            this.$http.post('/add_member', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    setTimeout(() => {
                        this.loading = false
                        this.$router.go()
                    }, 1000);
                } else {
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: 'Success',
            message: 'New member added, page will now refresh'
        }
    }
}
</script>

<style scoped>
    .add {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .upload {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        border: 1px dashed var(--main);
        margin: 0 auto;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;
    }
    .up_t {
        text-align: center;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .form {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 500px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .t {
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }

    .grid {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-gap: 20px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        font-weight: 500;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
    }
    .hidden {
        display: none;
    }
</style>