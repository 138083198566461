import Vue from 'vue'
import VueNotifications from 'vue-notifications'
import iziToast from 'izitoast'// https://github.com/dolce/iziToast
// import 'izitoast/dist/css/iziToast.min.css'
import '../assets/css/iziToast.min.css'

function toast ({title, message, type, timeout, /*cb*/}) {
  if (type === VueNotifications.types.warn) type = 'warning'
  return iziToast[type]({title, message, timeout})
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

let notify = Vue.use(VueNotifications, options)
export default notify
