<template>
    <div class="home">
        <div class="search">
            <div class="title">Search Pastors</div>
            <div class="input">
                <div class="check" v-if="status == ''"> </div>
                <check :size="'14px'" v-else class="icon"/>

                <div class="label">Status</div>
                <select v-model="status">
                    <option value="" disabled selected>status</option>
                    <option value=""></option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Gaffed">Gaffed</option>
                    <option value="Deceased">Deceased</option>
                </select>
            </div>
            <div class="input">
                <div class="check" v-if="group == ''"></div>
                <check :size="'14px'" v-else class="icon"/>
                <div class="label">Church Group</div>
                <select v-model="group">
                    <option value="" disabled selected>Church Group</option>
                    <option value=""></option>
                    <option v-for="g in groups" :value="g" :key="g">{{g}}</option>
                </select>
            </div>
            <div class="input">
                <div class="check" v-if="location == ''"> </div>
                <check :size="'14px'" v-else class="icon"/>

                <div class="label">Location</div>
                <select v-model="location">
                    <option value="" disabled selected>location</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Amukpe (Delta)">Amukpe (Delta)</option>
                    <option value="Opi (Enugu)">Opi (Enugu)</option>
                    <option value="Owerri (Imo)">Owerri (Imo)</option>
                </select>
            </div>
            <div class="input">
                <div class="check" v-if="state_of_origin == ''"></div>
                <check :size="'14px'" v-else class="icon"/>

                <div class="label">State of Origin</div>
                <input type="text" placeholder="Enter State of Origin" v-model="state_of_origin">
            </div>
            <div class="input">
                <div class="check" v-if="gender == ''"> </div>
                <check :size="'14px'" v-else class="icon"/>

                <div class="label">Gender</div>
                <select v-model="gender">
                    <option value="" disabled selected>Gender</option>
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>
            <div class="input">
                <div class="check" v-if="from == '' || to == ''"></div>
                <check :size="'14px'" v-else class="icon"/>

                <div class="label">Age</div>
                <div class="grid">
                    <input type="number" placeholder="From" v-model="from">
                    <div>-</div>
                    <input type="number" placeholder="To" v-model="to">
                </div>
            </div>
            <div class="input">
                <div class="check" v-if="name == ''"></div>
                <check :size="'14px'" v-else class="icon"/>
                <div class="label">Name</div>
                <input type="text" placeholder="Enter Name" v-model="name">
            </div>
            <loader :height="'37px'" class="l" v-if="loading"/>
            <div class="btn" @click="search()" v-else>Search</div>
        </div>
    </div>
</template>

<script>
import check from '../icons/check.vue'
import loader from '../components/loading.vue'

export default {
    name: 'Home',
    components: {
        check, loader
    },
    data() {
        return {
            loading: false,
            status: '',
            groups: [
                'ALTAR PASTORS', 'INNER CIRCLE SAINT', 'SAINTS OF HONOUR', 'RUTH SISTERS', 'LADIDI WOMEN',
                'MIRACLE PASTORS', 'SOLIDER PASTORS', 'INTERCESSORY PASTORS', 'SERIDYL', 'INGLOMICO'
            ],
            group: '',
            location: '',
            state_of_origin: '',
            gender: '',
            from: '',
            to: '',
            name: ''
        }
    },
    methods: {
        search() {
            this.loading = true
            let form = new FormData()
            form.append('status', this.status)
            form.append('group', this.group)
            form.append('location', this.location)
            form.append('state_of_origin', this.state_of_origin)
            form.append('gender', this.gender)
            form.append('from', this.from)
            form.append('to', this.to)
            form.append('name', this.name)

            this.$http.post('/search', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    localStorage.setItem('members', JSON.stringify(r.members))
                    setTimeout(() => {
                        this.$router.push('/results')
                    }, 500);
                }
            })
        }
    }
}
</script>

<style scoped>
    .home {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #edeef0;
    }

    .search {
        background-color: white;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .input {
        display: grid;
        grid-template-columns: max-content max-content auto;
        grid-gap: 10px;
        align-items: center;
        margin-top: 20px;
        /* fill: var(--main); */
    }
    .icon {
        fill: var(--main);
    }
    .check {
        width: 12px;
        height: 12px;
        border: 1px solid #ccc;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
    }
    input, select {
        padding: 8px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .grid {
        display: grid;
        grid-template-columns: auto max-content auto;
        grid-gap: 10px;
        align-items: center;
    }

    .l {
        margin-top: 20px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
    }
</style>
