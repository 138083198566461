<template>
    <div class="box">
        <div class="title">Change Password</div>
        <div class="main">
            <div class="input">
                <div class="label">Old Password</div>
                <input type="password" placeholder="Enter your old password" v-model="old">
                <div class="err" v-if="p">Incorrect password</div>
            </div>
            <div class="input">
                <div class="label">New Password</div>
                <input type="password" placeholder="Enter new password" v-model="password">
            </div>

            <loader :height="'45px'" v-if="loading"/>
            <div class="btn" v-else @click="change()">Change</div>
        </div>
    </div>
</template>

<script>
import loader from '../loading.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            old: '',
            password: '',
            p: false,
            loading: false
        }
    },
    methods: {
        change() {
            if (this.old == '' || this.password == '') {
                this.empty(); return
            }
            this.loading = true
            this.p = false

            let form = new FormData()
            form.append('new', this.password)
            form.append('old', this.old)

            this.$http.post('/settings/password', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.changed()
                    this.old = ''
                    this.password = ''
                } else {
                    if (r.password) {
                        this.p = true
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Enter new password and your password'
        },
        changed: {
            type: 'success',
            title: 'success',
            message: 'Your password has been changed'
        }
    }
}
</script>

<style scoped>
    .box {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }

    .main {
        /* max-width: 300px; */
        margin-top: 30px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .err {
        position: absolute;
        right: 0px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        text-align: center;
        padding: 12px;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 600;
    }

    @media screen and (max-width: 380px) {
        .main {
            width: unset;
        }
    }
</style>