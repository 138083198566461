import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Results from '../views/results.vue'
import Member from '../views/member.vue'
import login from '../views/login.vue'
import Admin from '../views/admin.vue'
import dashboard from '../views/dashboard.vue'
import add_member from '../views/Add_member.vue'
import members from '../views/members.vue'
import complete from '../views/complete.vue'
import incomplete from '../views/incomplete.vue'
import profile from '../views/profile.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/results',
        name: 'Results',
        component: Results
    },
    {
        path: '/member',
        name: 'Member',
        component: Member
    },
    {
        path: '/login',
        name: 'Login',
        component: login
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'add',
                name: 'add',
                component: add_member
            },
            {
                path: 'pastors',
                name: 'members',
                component: members
            },
            {
                path: 'complete',
                name: 'complete',
                component: complete
            },
            {
                path: 'incomplete',
                name: 'incomplete',
                component: incomplete
            },
            {
                path: 'profile',
                name: 'profile',
                component: profile
            }
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
  routes
})

export default router
