<template>
    <div class="dashboard">
        <div class="title">
            Dashboard
        </div>
        <div class="grid">
            <div class="item">
                <div class="label">NUMBER OF PASTORS</div>
                <div class="no">{{t}}</div>
            </div>
            <div class="item">
                <div class="label">ACTIVE PASTORS</div>
                <div class="no">{{a}}</div>
            </div>
            <div class="item">
                <div class="label">INACTIVE PASTORS</div>
                <div class="no">{{i}}</div>
            </div>
            <div class="item">
                <div class="label">DECEASED PASTORS</div>
                <div class="no">{{d}}</div>
            </div>
            <div class="item">
                <div class="label">GAFFED PASTORS</div>
                <div class="no">{{g}}</div>
            </div>
            <div class="item">
                <div class="label">Excommunicated</div>
                <div class="no">{{e}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            t: 0,
            a: 0,
            i: 0,
            g: 0,
            d: 0,
            e: 0
        }
    },
    mounted() {
        this.$http.get('/dashboard')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.t = r.t
                this.a = r.a
                this.i = r.i
                this.g = r.g
                this.d = r.d
                this.e = r.e
            }
        })
    }
}
</script>

<style scoped>

    .dashboard {
        padding: 30px;
    }
    .title {
        font-size: 25px;
        font-weight: 400;
    }

    .grid {
        display: grid;
        /* grid-template-columns: auto auto auto auto; */
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 30px;
        margin: 30px 0px;
    }
    .item {
        background-color: white;
        padding: 20px;
        box-shadow: 0px 0px 20px #ccc;
        border-radius: 10px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
        color: var(--main);
    }
    .no {
        font-size: 30px;
        margin-top: 5px;
    }
</style>