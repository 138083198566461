<template>
    <div class="member">
        <div class="main">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="result">
                <div>
                    <div class="image">
                        <img :src="m.image" alt="">
                    </div>
                </div>
                <div class="info">
                    <div class="flex">
                        <div class="label">Status:</div>
                        <div class="value">{{m.status}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Church Group:</div>
                        <div class="value">{{m.church_group}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Firstname:</div>
                        <div class="value">{{m.firstname}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Other Names:</div>
                        <div class="value">{{m.other_names}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Surname:</div>
                        <div class="value">{{m.surname}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Gender:</div>
                        <div class="value">{{m.gender}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Marital Status:</div>
                        <div class="value">{{m.marital}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Spouse Name:</div>
                        <div class="value">{{m.spouse}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Children:</div>
                        <div class="value">{{m.children}}</div>
                    </div>
                </div>
            </div>

            <div class="flex">
                <div class="label">Address:</div>
                <div class="value">{{m.address}}</div>
            </div>
            <div class="flex">
                <div class="label">City:</div>
                <div class="value">{{m.city}}</div>
            </div>
            <div class="flex">
                <div class="label">State:</div>
                <div class="value">{{m.state}}</div>
            </div>
            <div class="flex">
                <div class="label">Postal Code:</div>
                <div class="value">{{m.postal}}</div>
            </div>
            <div class="flex">
                <div class="label">Country:</div>
                <div class="value">{{m.country}}</div>
            </div>
            <div class="flex">
                <div class="label">Email Address:</div>
                <div class="value">{{m.email}}</div>
            </div>
            <div class="flex">
                <div class="label">Phone 1:</div>
                <div class="value">{{m.home_phone}}</div>
            </div>
            <div class="flex">
                <div class="label">Phone 2:</div>
                <div class="value">{{m.mobile_phone}}</div>
            </div>
            <div class="flex">
                <div class="label">Phone 3:</div>
                <div class="value">{{m.altar_phone}}</div>
            </div>
            <div class="flex">
                <div class="label">Phone 4:</div>
                <div class="value">{{m.fax}}</div>
            </div>
            <div class="flex">
                <div class="label">Birthday:</div>
                <div class="value">{{m.birthday}}</div>
            </div>
            <div class="flex">
                <div class="label">Nationality:</div>
                <div class="value">{{m.nationality}}</div>
            </div>
            <div class="flex">
                <div class="label">Village/Town:</div>
                <div class="value">{{m.village}}</div>
            </div>
            <div class="flex">
                <div class="label">LGA/Country:</div>
                <div class="value">{{m.lga}}</div>
            </div>
            <div class="flex">
                <div class="label">State of Origin:</div>
                <div class="value">{{m.state_of_origin}}</div>
            </div>
            <div class="flex">
                <div class="label">Present Location:</div>
                <div class="value">{{m.location}}</div>
            </div>
            <div class="flex">
                <div class="label">Date Join BCG:</div>
                <div class="value">{{m.joined}}</div>
            </div>
            <div class="flex">
                <div class="label">Emergency Contact Name:</div>
                <div class="value">{{m.contact_name}}</div>
            </div>
            <div class="flex">
                <div class="label">Emergency Contact Phone:</div>
                <div class="value">{{m.contact_phone}}</div>
            </div>
            <div class="title">Banking Details</div>
            <div class="flex">
                <div class="label">Name:</div>
                <div class="value">{{m.bank_name}}</div>
            </div>
            <div class="flex">
                <div class="label">Account Number:</div>
                <div class="value">{{m.acct_no}}</div>
            </div>
            <div class="flex">
                <div class="label">Bank:</div>
                <div class="value">{{m.bank}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../icons/back.vue'
export default {
    components: {
        back
    },
    data() {
        return {
            m: {},
            imgurl: this.$root.$data.imgurl
        }
    },
    mounted() {
        this.m = JSON.parse(localStorage.getItem('member'))
    },
    methods: {
        back() {
            localStorage.removeItem('member')

            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>

    .member {
        background-color: #edeef0;
        padding: 30px;
    }
    .main {
        max-width: 500px;
        margin: 0 auto;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    .back {
        margin-bottom: 20px;
        cursor: pointer;
        width: fit-content;
    }
    .result {
        display: grid;
        grid-template-columns: max-content auto;
        /* padding: 20px; */
        border-radius: 5px;
        background-color: white;
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    .image {
        width: 150px;
        overflow: hidden;
        border-radius: 5px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        padding: 5px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex:nth-child(even) {
        background-color: var(--trans);
        /* border-radius: 5px; */
    }
    .label {
        margin-right: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }

    .title {
        font-weight: 500;
        text-align: center;
        margin: 15px 0px;
    }

    @media screen and (max-width: 560px) {
        .member {
            padding: 20px;
        }
        .result {
            grid-template-columns: auto;
        }
    }
</style>