<template>
    <div class="menu">
        <router-link to="/admin/dashboard">
            <div class="item" :class="[c == 'dashboard' ? 'a' : '']">
                <div class="icon">
                    <dashboard :size="'15px'"/>
                </div>
                Dashboard
            </div>
        </router-link>
        <router-link to="/admin/add">
            <div class="item" :class="[c == 'add' ? 'a' : '']">
                <div class="icon">
                    <plus :size="'15px'"/>
                </div>
                Add a pastor
            </div>
        </router-link>
        <router-link to="/admin/pastors">
            <div class="item" :class="[c == 'members' ? 'a' : '']">
                <div class="icon">
                    <pastors :size="'15px'"/>
                </div>
                Pastors
            </div>
        </router-link>
        <router-link to="/admin/complete">
            <div class="item" :class="[c == 'complete' ? 'a' : '']">
                <div class="icon">
                    <check :size="'15px'"/>
                </div>
                Complete Information
            </div>
        </router-link>
        <router-link to="/admin/incomplete">
            <div class="item" :class="[c == 'incomplete' ? 'a' : '']">
                <div class="icon">
                    <half :size="'15px'"/>
                </div>
                Incomplete Information
            </div>
        </router-link>
        <router-link to="/admin/profile">
            <div class="item" :class="[c == 'profile' ? 'a' : '']">
                <div class="icon">
                    <user :size="'15px'"/>
                </div>
                Profile
            </div>
        </router-link>
    </div>
</template>

<script>
import dashboard from '../icons/dashboard.vue'
import plus from '../icons/plus.vue'
import pastors from '../icons/pastors.vue'
import user from '../icons/user.vue'
import check from '../icons/complete.vue'
import half from '../icons/about.vue'

export default {
    components: {
        dashboard, plus, pastors, user, check, half
    },
    computed: {
        c() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>
    .menu {
        background-color: white;

        /* padding: 5px; */
        border-top: 1px solid #ccc;
    }
    .item {
        /* background-color: white; */
        padding: 20px 15px;
        /* border-radius: 10px; */
        cursor: pointer;
        font-weight: 500;
        background-color: var(--trans);
        color: var(--main);
        /* opacity: .5; */
        display: flex;
        align-items: center;
        fill: var(--main);
        margin: 15px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
    }
    .icon {
        margin-right: 10px;
    }
    .a {
        opacity: 1;
        background-color: var(--main);
        color: white;
        fill: white;
    }
</style>