<template>
    <div class="user">
        <div class="grid">
            <div class="f">
                <div class="icon" @click="a = !a">
                    <arrow :size="'20px'" :style="{'height': '20px'}" 
                        :class="[a ? 'a' : '']" class="arr"/>
                </div>
                <div class="image">
                    <img :src="m.image"/>
                </div>
                <div class="name">
                    {{m.surname}} {{m.firstname}}
                </div>
            </div>
            <div class="item">
                {{m.church_group}}
            </div>
            <div class="item">
                {{m.state_of_origin}}
            </div>
            <div class="item">
                {{age}}
            </div>
            <div class="item">
                {{m.home_phone}}
            </div>
            <div class="item">
                {{m.location}}
            </div>
            <div class="item">
                {{m.status}}
            </div>
        </div>
        <div class="info" v-if="a">
            <div class="details">
                <div class="btn" @click="pop = true">Edit</div>
                <div>
                    <div class="image bigImage">
                        <img :src="m.image" class="b_img" >
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Status:</div>
                    <div class="value">{{m.status}}</div>
                </div>
                <div class="flex">
                    <div class="label">Church Group:</div>
                    <div class="value">{{m.church_group}}</div>
                </div>
                <div class="flex">
                    <div class="label">First Name:</div>
                    <div class="value">{{m.firstname}}</div>
                </div>
                <div class="flex">
                    <div class="label">Other Names:</div>
                    <div class="value">{{m.other_names}}</div>
                </div>
                <div class="flex">
                    <div class="label">Surname:</div>
                    <div class="value">{{m.surname}}</div>
                </div>
                <div class="flex">
                    <div class="label">Gender:</div>
                    <div class="value">{{m.gender}}</div>
                </div>
                <div class="flex">
                    <div class="label">Marital Status:</div>
                    <div class="value">{{m.marital}}</div>
                </div>
                <div class="flex">
                    <div class="label">Spouse Name:</div>
                    <div class="value">{{m.spouse}}</div>
                </div>
                <div class="flex">
                    <div class="label">Children:</div>
                    <div class="value">{{m.children}}</div>
                </div>

                <div class="flex">
                    <div class="label">Address:</div>
                    <div class="value">{{m.address}}</div>
                </div>
                <div class="flex">
                    <div class="label">City:</div>
                    <div class="value">{{m.city}}</div>
                </div>
                <div class="flex">
                    <div class="label">State:</div>
                    <div class="value">{{m.state}}</div>
                </div>
                <div class="flex">
                    <div class="label">Postal Code:</div>
                    <div class="value">{{m.postal}}</div>
                </div>
                <div class="flex">
                    <div class="label">Country:</div>
                    <div class="value">{{m.country}}</div>
                </div>
                <div class="flex">
                    <div class="label">Email Address:</div>
                    <div class="value">{{m.email}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone 1:</div>
                    <div class="value">{{m.home_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone 2:</div>
                    <div class="value">{{m.mobile_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone 3:</div>
                    <div class="value">{{m.altar_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone 4:</div>
                    <div class="value">{{m.fax}}</div>
                </div>
                <div class="flex">
                    <div class="label">Birthday:</div>
                    <div class="value">{{m.birthday}}</div>
                </div>
                <div class="flex">
                    <div class="label">Nationality:</div>
                    <div class="value">{{m.nationality}}</div>
                </div>
                <div class="flex">
                    <div class="label">Village/Town:</div>
                    <div class="value">{{m.village}}</div>
                </div>
                <div class="flex">
                    <div class="label">LGA/Country:</div>
                    <div class="value">{{m.lga}}</div>
                </div>
                <div class="flex">
                    <div class="label">State of Origin:</div>
                    <div class="value">{{m.state_of_origin}}</div>
                </div>
                <div class="flex">
                    <div class="label">Present Location:</div>
                    <div class="value">{{m.location}}</div>
                </div>
                <div class="flex">
                    <div class="label">Date Join BCG:</div>
                    <div class="value">{{m.joined}}</div>
                </div>
                <div class="flex">
                    <div class="label">Emergency Contact Name:</div>
                    <div class="value">{{m.contact_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Emergency Contact Phone:</div>
                    <div class="value">{{m.contact_phone}}</div>
                </div>
                <div class="title">Banking Details</div>
                <div class="flex">
                    <div class="label">Name:</div>
                    <div class="value">{{m.bank_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Account Number:</div>
                    <div class="value">{{m.acct_no}}</div>
                </div>
                <div class="flex">
                    <div class="label">Bank:</div>
                    <div class="value">{{m.bank}}</div>
                </div>
                <div class="r">
                    <div class="label">Remarks:</div>
                    <div class="value">{{m.remarks}}</div>
                </div>
            </div>
        </div>
        <div class="popup" v-if="pop">
            <edit @close="pop = false" :m="m"/>
        </div>
    </div>
</template>

<script>
import arrow from '../icons/arrow.vue'
import edit from './edit.vue'
export default {
    components: {
        arrow, edit
    },
    props: [
        'm'
    ],
    data() {
        return {
            a: false,
            pop: false,
            imgurl: this.$root.$data.imgurl,
            age: 0
        }
    },
    mounted() {
        let date, y, n
        date = new Date(this.m.birthday)
        y = date.getFullYear()

        date = new Date()
        n = date.getFullYear()

        this.age = n - y
    }
}
</script>

<style scoped>
    .user {
        border-bottom: 1px solid #ccc;
    }
    .grid {
        display: grid;
        grid-template-columns: 25% 15% 10% 7% 15% 18% 10%;
        align-items: center;
        padding: 10px 20px;
    }
    .f {
        display: grid;
        grid-template-columns: 30px 30px auto;
        grid-gap: 10px;
        align-items: center;
        
        /* font-weight: 500; */
    }
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        cursor: pointer;
        fill: var(--main);
    }
    .arr {
        transition: .2s;
    }
    .a {
        transform: rotate(90deg);
    }

    .image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
    }
    img {
        width: 30px;
    }
    .bigImage {
        width: 100px;
        height: 100px;
        border: none;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .b_img {
        width: 100px;
    }


    .info {
        border-top: 1px solid #ccc;
        padding: 20px;
    }

    
    .details {
        max-width: 400px;
        position: relative;
    }
    .btn {
        background-color: var(--main);
        color: white;
        position: absolute;
        top: 0px;
        right: 0px;
        width: fit-content;
        padding: 5px 20px;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex:nth-child(even) {
        background-color: var(--trans);
        /* border-radius: 5px; */
    }
    .label {
        margin-right: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }
    .r {
        padding: 10px;
    }
    
    .title {
        font-weight: 500;
        text-align: center;
        margin: 15px 0px;
    }


    .popup {
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
    }
</style>