<template>
    <div class="head">
        <div class="logo">BCG Database</div>
        <div class="btn" @click="logout()">Logout</div>
    </div>
</template>

<script>
import Localbase from 'localbase'
export default {
    data() {
        return {
            db: new Localbase('bcgdata')
        }
    },
    methods: {
        logout() {
            this.db.collection('auth').delete().then(() => {
                this.$router.push('/login')
            })
        }
    }
}
</script>

<style scoped>
    .head {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* box-shadow: 0px 20px 20px #222; */
    }

    .logo {
        font-size: 20px;
        font-weight: bold;
        color: var(--main);
    }
    .btn {
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        color: var(--main);
        background-color: var(--trans);
    }
</style>