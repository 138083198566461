<template>
    <div class="mm">
    <div class="m">
        <router-link to="/admin/dashboard">
            <div class="item" :class="[c == 'dashboard' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <dashboard :size="'15px'"/>
                    </div>
                    <div class="t">Dashboard</div>
                </div>
            </div>
        </router-link>

        <router-link to="/admin/add">
            <div class="item" :class="[c == 'add' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <plus :size="'15px'"/>
                    </div>
                    <div class="t">Add Pastor</div>
                </div>
            </div>
        </router-link>
        
        <router-link to="/admin/pastors">
            <div class="item" :class="[c == 'members' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <pastors :size="'15px'"/>
                    </div>
                    <div class="t">Pastors</div>
                </div>
            </div>
        </router-link>

        <router-link to="/admin/complete">
            <div class="item" :class="[c == 'complete' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <check :size="'15px'"/>
                    </div>
                    <div class="t">Complete</div>
                </div>
            </div>
        </router-link>

        <router-link to="/admin/incomplete">
            <div class="item" :class="[c == 'incomplete' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <half :size="'15px'"/>
                    </div>
                    <div class="t">Incomplete</div>
                </div>
            </div>
        </router-link>
        <router-link to="/admin/profile">
            <div class="item" :class="[c == 'profile' ? 'a' : '']">
                <div>
                    <div class="icon">
                        <user :size="'15px'"/>
                    </div>
                    <div class="t">Profile</div>
                </div>
            </div>
        </router-link>
    </div>
    </div>
</template>

<script>
import dashboard from '../icons/dashboard.vue'
import plus from '../icons/plus.vue'
import pastors from '../icons/pastors.vue'
import user from '../icons/user.vue'
import check from '../icons/complete.vue'
import half from '../icons/about.vue'

export default {

    components: {
        dashboard, plus, pastors, user, check, half
    },
    computed: {
        c() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>
    .mm {
        /* width: 100vw; */
        overflow: auto;
        background-color: var(--main);
    }
    .m {
        display: grid;
        /* grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%; */
        grid-template-columns: 20% 20% 20% 20% 20% 20%;
        background-color: var(--main);
        /* align-items: center; */
        /* padding: 10px; */
    }
    .item {
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        fill: white;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding: 10px; */
    }
    .t {
        margin-top: 1px;
    }
    .a {
        background-color: white;
        fill: var(--main);
        color: var(--main);
    }
</style>