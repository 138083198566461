<template>
    <div class="profile">

        <div class="title"> Profile </div>
        <div class="grid grid2">
            <add/>
            <empty v-if="!loading && notes.length == 0"/>

            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>

            <note v-for="note in notes" :key="note.id" :note="note"/>
        </div>
        <div class="grid">
            <username/>
            <password/>
        </div>
    </div>
</template>

<script>
import add from '../components/profile/add.vue'
import empty from '../components/profile/empty.vue'
import note from '../components/profile/note.vue'
import username from '../components/profile/username.vue'
import password from '../components/profile/password.vue'
export default {
    components: {
        add, empty,
        note,
        username,password
    },
    data() {
        return {
            notes: [],
            loading: false
        }
    },
    mounted() {
        this.loading = true
        this.$http.get('/notes/get')
        .then( data => {
            let r = data.data
            if (r.success) {
                this.loading = false
                this.notes = r.notes
            }
        })
    }
}
</script>

<style scoped>

    .profile {
        padding: 30px;
    }
    .title {
        font-size: 25px;
        font-weight: 400;
    }

    .grid {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        margin-top: 30px;
    }

    .grid2 {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .loading {
        height: 100%;
        min-height: 200px;
        display: flex;
        align-items: center;
    }
    .spin {
        margin: 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>