<template>
    <div class="box">
        <div class="title">Add A Note</div>
        
        <div class="input">
            <div class="label" v-if="title != ''">Title</div>
            <input type="text" placeholder="Note Title" v-model="title">
        </div>
        <div class="input">
            <div class="label" v-if="note != ''">Note</div>
            <textarea type="text" placeholder="Enter Note Content" 
                v-model="note"></textarea>
        </div>

        <loader :height="'45px'" v-if="loading"/>
        <div class="btn" @click="save()" v-else>Save</div>
    </div>
</template>

<script>
import loader from '../loading.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            title: '',
            note: '',
            loading: false
        }
    },
    methods: {
        save() {
            if (this.title == '' || this.note == '') { this.empty(); return }

            this.loading = true

            let form = new FormData()
            form.append('title', this.title)
            form.append('note', this.note)

            this.$http.post('/notes/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$router.go()
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter note title and description'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Note added'
        }
    }
}
</script>

<style scoped>

    .box {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, textarea {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .err {
        position: absolute;
        right: 0px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        text-align: center;
        padding: 12px;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 600;
    }
</style>