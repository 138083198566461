<template>
    <div class="edit">
        <div class="remove" @click="$emit('close')">
            <remove :size="'30px'"/>
        </div>
        <div class="title">
            Edit
        </div>
        <div class="main">
            <div class="form">
                <div class="upload" @click="upload()">
                    <div class="up_t" v-if="image == ''">Click to select a picture</div>
                    <img :src="image" alt="" v-else>
                </div>
                <div class="input">
                    <div class="label" v-if="status != ''">Status</div>
                    <select v-model="status">
                        <option value="" disabled selected>Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Gaffed">Gaffed</option>
                        <option value="Excommunicated">Excommunicated</option>
                        <option value="Deceased">Deceased</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label" v-if="group != ''">Church Group</div>
                    <select v-model="group">
                        <option value="" disabled selected>Church Group</option>
                        <option v-for="g in groups" :value="g" :key="g">{{g}}</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label" v-if="firstname != ''">Firstname</div>
                    <input type="text" placeholder="Enter Firstname" v-model="firstname">
                </div>
                <div class="input">
                    <div class="label" v-if="othername != ''">Other Names</div>
                    <input type="text" placeholder="Enter Other Names" v-model="othername">
                </div>
                <div class="input">
                    <div class="label" v-if="surname != ''">Surname</div>
                    <input type="text" placeholder="Enter Surname" v-model="surname">
                </div>
                <div class="input">
                    <div class="label" v-if="gender != ''">Gender</div>
                    <select v-model="gender">
                        <option value="" disabled selected>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label" v-if="marital != ''">Marital Status</div>

                    <select v-model="marital">
                        <option value="" disabled selected> Marital Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Seperated">Seperated</option>
                        <option value="Widowed">Widowed</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label" v-if="spouse_name != ''">Spouse Name</div>
                    <input type="text" placeholder="Enter Spouse Name" v-model="spouse_name">
                </div>
                <div class="input">
                    <div class="label" v-if="children != ''">Children</div>
                    <input type="text" placeholder="Children" v-model="children">
                </div>
                <div class="input">
                    <div class="label" v-if="address != ''">Address</div>
                    <input type="text" placeholder="Enter Address" v-model="address">
                </div>
                <div class="input">
                    <div class="label" v-if="city != ''">City</div>
                    <input type="text" placeholder="Enter City" v-model="city">
                </div>
                <div class="input">
                    <div class="label" v-if="state != ''">State</div>
                    <input type="text" placeholder="Enter State" v-model="state">
                </div>
                <div class="input">
                    <div class="label" v-if="postal != ''">Postal Code</div>
                    <input type="text" placeholder="Enter Postal Code" v-model="postal">
                </div>
                <div class="input">
                    <div class="label" v-if="country != ''">Country</div>
                    <input type="text" placeholder="Enter Country" v-model="country">
                </div>
                <div class="input">
                    <div class="label" v-if="email != ''">Email</div>
                    <input type="text" placeholder="Enter Email" v-model="email">
                </div>
                <div class="input">
                    <div class="label" v-if="home_phone != ''">Home Phone</div>
                    <input type="text" placeholder="Enter Home Phone" v-model="home_phone">
                </div>
                <div class="input">
                    <div class="label" v-if="mobile_phone != ''">Mobile Phone</div>
                    <input type="text" placeholder="Enter Mobile Phone" v-model="mobile_phone">
                </div>
                <div class="input">
                    <div class="label" v-if="altar_phone != ''">Alter Phone</div>
                    <input type="text" placeholder="Enter Alter Phone" v-model="altar_phone">
                </div>
                <div class="input">
                    <div class="label" v-if="fax != ''">Fax Number</div>
                    <input type="text" placeholder="Enter Fax Number" v-model="fax">
                </div>
                <div class="input">
                    <div class="label">Birthday</div>
                    <input type="date" placeholder="Enter Birthday" v-model="b">
                </div>
                <div class="input">
                    <div class="label" v-if="nationality != ''">Nationality</div>
                    <input type="text" placeholder="Enter Nationality" v-model="nationality">
                </div>
                <div class="input">
                    <div class="label" v-if="village != ''">Village/Town</div>
                    <input type="text" placeholder="Enter Village/Town" v-model="village">
                </div>
                <div class="input">
                    <div class="label" v-if="lga != ''">LGA/Country</div>
                    <input type="text" placeholder="Enter LGA/Country" v-model="lga">
                </div>
                <div class="input">
                    <div class="label" v-if="s_o_o != ''">State Of Origin</div>
                    <input type="text" placeholder="Enter State Of Origin" v-model="s_o_o">
                </div>
                <div class="input">
                    <div class="label" v-if="location != ''">Present Location</div>
                    <select v-model="location">
                        <option value="" disabled selected>Present Location</option>
                        <option v-for="l in locations" :key="l" :value="l">{{l}}</option>
                    </select>
                </div>
                <div class="input">
                    <div class="label">Date Joined BCG</div>
                    <input type="date" placeholder="Enter Present Location" v-model="j">
                </div>
                <div class="input">
                    <div class="label" v-if="emer_name != ''">Emergency Contact Name</div>
                    <input type="text" placeholder="Enter Emergency Name" v-model="emer_name">
                </div>
                <div class="input">
                    <div class="label" v-if="emer_phone != ''">Emergency Contact Number</div>
                    <input type="number" placeholder="Enter Emergency Contact Number" v-model="emer_phone">
                </div>
                <div class="t">Bank Details</div>
                <div class="input">
                    <div class="label" v-if="bank_acct_name != ''">Bank Account Name</div>
                    <input type="text" placeholder="Enter Bank Account Name" v-model="bank_acct_name">
                </div>
                <div class="input">
                    <div class="label" v-if="acct_no != ''">Account Number</div>
                    <input type="number" placeholder="Enter Account Number" v-model="acct_no">
                </div>
                <div class="input">
                    <div class="label" v-if="bank_name != ''">Bank Name</div>
                    <input type="text" placeholder="Enter Bank Name" v-model="bank_name">
                </div>
                <div class="input">
                    <div class="label" v-if="remarks != ''">Remarks</div>
                    <textarea placeholder="Enter Remarks" v-model="remarks"></textarea>
                </div>
                <loader :height="'37px'" v-if="loading"/>
                <div class="grid" v-else>
                    <div class="btn" @click="edit('draft')">Save as draft</div>
                    <div class="btn" @click="edit('ok')">Save</div>
                </div>
                <input type="file" accept="image/*" class="hidden" ref="image" @change="handleDp($event)"/>
            </div>
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import remove from '../icons/remove.vue'
import loader from './loading.vue'
export default {
    components: {
        remove, loader
    },
    props: ['m'],
    mounted() {
        
        this.image = this.m.image
        this.img_name = this.m.img_name
        this.status = this.m.status
        this.group = this.m.church_group
        this.firstname = this.m.firstname
        this.surname = this.m.surname
        this.othername = this.m.other_names
        this.gender = this.m.gender
        this.marital = this.m.marital
        this.spouse_name = this.m.spouse
        this.children = this.m.children
        this.address = this.m.address
        this.city = this.m.city
        this.state = this.m.state
        this.postal = this.m.postal
        this.country = this.m.country
        this.email = this.m.email

        this.home_phone = this.m.home_phone
        this.mobile_phone = this.m.mobile_phone
        this.altar_phone = this.m.altar_phone
        this.fax = this.m.fax
        this.birthday = this.m.birthday
        this.nationality = this.m.nationality
        this.village = this.m.village
        this.lga = this.m.lga
        this.s_o_o = this.m.state_of_origin
        this.location = this.m.location
        this.joined = this.m.joined
        this.emer_name = this.m.contact_name
        this.emer_phone = this.m.contact_phone
        this.bank_acct_name = this.m.bank_name
        this.acct_no = this.m.acct_no
        this.bank_name = this.m.bank
        this.remarks = this.m.remarks

        let date
        date = new Date(this.m.birthday);
        this.birthday = date.getTime() / 1000;
        
        date = new Date(this.m.joined);
        this.joined = date.getTime() / 1000;
    },
    watch: {
        b(val) {
            var date = new Date(val);
            this.birthday = date.getTime() / 1000;
            console.log(this.birthday);
        },
        j(val) {
            var date = new Date(val);
            this.joined = date.getTime() / 1000;
            console.log(this.joined);
        }
    },
    data() {
        return {
            is_image: false,
            image: '',
            pic: null,
            img_name: '',
            status: '',
            group: '',
            groups: [
                'ALTAR PASTORS', 'INNER CIRCLE SAINT', 'SAINTS OF HONOUR', 'RUTH SISTERS', 'LADIDI WOMEN',
                'MIRACLE PASTORS', 'SOLIDER PASTORS', 'INTERCESSORY PASTORS', 'SERIDYL', 'INGLOMICO'
            ],
            firstname: '',
            surname: '',
            othername: '',
            gender: '',
            marital: '',
            spouse_name: '',
            children: '',
            address: '',
            city: '',
            state: '',
            postal: '',
            country: '',
            email: '',
            home_phone: '',
            mobile_phone: '',
            altar_phone: '',
            fax: '',
            birthday: '', b: '',
            nationality: '',
            village: '',
            lga: '',
            s_o_o: '',
            location: '',
            locations: ['Lagos', 'Amukpe (Delta)', 'Opi (Enugu)', 'Owerri (Imo)'],
            joined: '', j: '',
            emer_name: '',
            emer_phone: '',
            bank_acct_name: '',
            acct_no: '',
            bank_name: '',
            remarks: '',
            loading: false
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl
        }
    },
    methods: {
        upload() {
            this.$refs['image'].click()
        },
        handleDp(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result
                    this.is_image = true
                }

                
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 1000,
                    useWebWorker: true
                }
                imageCompression(picture, options).then( res => {
                    // console.log(res)
                    this.pic = new File([res], picture.name)
                })
            }
        },
        
        edit(has) {
            this.loading = true
            let data = this.$data

            let k
            let form = new FormData()
            for (k in data) {
                form.append(k, this[k])
            }
            form.delete('groups')
            form.delete('loading')
            form.delete('b')
            form.delete('j')
            form.delete('locations')

            if (this.is_image) {
                let pic = this.pic
                form.append('picture', pic)
                form.delete('image')
            }

            form.append('save', has)
            form.append('member_id', this.m.member_id)

            this.$http.post('/edit_member', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    setTimeout(() => {
                        this.loading = false
                        this.$router.go()
                    }, 1000);
                } else {
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: 'Updated',
            message: 'Page will now refresh'
        }
    }
}
</script>

<style scoped>
    .edit {
        height: 80vh;
        width: 500px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
    }
    .remove {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        fill: tomato;
    }
    .title {
        background-color: var(--main);
        padding: 20px;
        color: white;
        font-weight: 500;
    }
    .main {
        height: calc(100% - 64px);
        overflow: auto;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
    }

    
    .upload {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        border: 1px dashed var(--main);
        margin: 0 auto;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;
    }
    .up_t {
        text-align: center;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .t {
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }

    .grid {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-gap: 20px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        /* font-weight: 500; */
        padding: 8px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
    }
    .hidden {
        display: none;
    }
</style>