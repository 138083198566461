<template>
    <div class="results">
        <div class="main">
            <div class="flex">
                <div class="back" @click="back()">
                    <back :size="'25px'"/>
                </div>
                <div class="title">
                    Search Results ({{members.length}})
                </div>
            </div>
            <div class="grid">
                <result v-for="m in members" :r="m" :key="m.member_id"/>
            </div>

            <div class="empty" v-if="!e">
                No member in the database match your query
            </div>
        </div>
    </div>
</template>

<script>
import result from '../components/result.vue'

import back from '../icons/back.vue'
export default {
    components: {
        result, back
    },
    data() {
        return {
            members: [],
            e: false
        }
    },
    mounted() {
        let m = localStorage.getItem('members')
        this.members = JSON.parse(m)

        this.e = this.members.length
    },
    methods: {
        back() {
            localStorage.removeItem('members')
            this.$router.back()
        }
    }
}
</script>

<style scoped>
    .results {
        /* background-color: #edeef0; */
    }
    .main {
        max-width: 1000px;
        margin: 0 auto;
        padding: 30px;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;
    }
    .title {
        font-weight: 600;
        font-size: 25px;
    }

    .back {
        padding-top: 5px;
        cursor: pointer;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-gap: 20px;
    }

    @media screen and (max-width: 440px) {
        .main {
            padding: 20px;
        }
        .flex {
            margin-bottom: 20px;
        }
        .grid {
            grid-template-columns: 100%;
        }
    }
</style>