import Vue from 'vue'
import App from './App.vue'
import router from './router'
import notify from './externals/notify'
import axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$http = axios
axios.defaults.baseURL = 'https://bcgdata.alwaysdata.net/index.php/'
// axios.defaults.baseURL = 'https://bcgdata.herokuapp.com/index.php/'
// axios.defaults.baseURL = 'http://localhost/bcgdata/index.php/'
axios.defaults.headers.common['Auth'] = '';

new Vue({
    router,
    notify,
    data() {
        return {
            colors: {
                main: 'rgb(34, 74, 190)',
                trans: 'rgba(34, 74, 190, 0.2)'
            },
            // imgurl: 'http://localhost/bcgdata/images/',
            imgurl: 'https://bcgdata.herokuapp.com/images/',
        }
    },
    render: h => h(App)
}).$mount('#app')
