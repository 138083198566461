<template>
    <div class="login">
        <div class="box">
            <div class="title">Admin Login</div>
            <div class="input">
                <div class="label" v-if="username != ''">Username:</div>
                <input type="text" placeholder="Username" v-model="username">
                <div class="err" v-if="u">user not found</div>
            </div>
            <div class="input">
                <div class="label" v-if="password != ''">Password:</div>
                <input type="password" placeholder="Password" v-model="password" 
                    @keyup.enter="click()" >
                <div class="err" v-if="p">Incorrect Password</div>
            </div>
            <loader :height="'37px'" v-if="loading"/>
            <div class="btn" v-else @click="login()" ref="btn">Login</div>
        </div>
    </div>
</template>

<script>
import loader from '../components/loading.vue'
import Localbase from 'localbase'
export default {
    components: {
        loader
    },
    data() {
        return {
            db: new Localbase('bcgdata'),
            username: '',
            password: '',
            u: false,
            p: false,
            loading: false
        }
    },
    mounted() {
        this.db.collection('auth').doc({ id: 1 }).get().then(data => {
            if (data == undefined) {
                // this.$router.push('/login')
            } else {
                this.$http.defaults.headers.common['Auth'] = data.key
                this.$router.push('/admin/add')
            }
        })
    },
    methods: {
        click() {
            this.$refs.btn.click()
        },
        login() {
            if (this.username == '') { this.no_user(); return }
            if (this.password == '') { this.no_pass(); return }

            this.loading = true
            this.u = false
            this.p = false

            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.db.collection('auth').add({
                        'id': 1,
                        'key': r.session
                    }).then(() => {
                        this.$http.defaults.headers.common['Auth'] = r.session
                        this.success()
                        setTimeout(() => {
                            this.$router.push('/admin/dashboard')
                        }, 1000);
                    })
                } else {
                    if (r.u) {
                        this.u = true
                    }
                    if (r.p) {
                        this.p = true
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        no_user: {
            type: 'warn',
            title: 'Username',
            message: 'Enter your username'
        },
        no_pass: {
            type: 'warn',
            title: 'Password',
            message: 'Enter admin password'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'You are now logged in'
        }
    }
}
</script>

<style scoped>
    .login {
        background-color: #edeef0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }
    .box {
        width: 350px;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 20px;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
        padding: 0px 5px;
        background-color: white;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -10px;
        }
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        outline: none;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 500;
        /* margin-top: 20px; */
        text-align: center;
    }
</style>