<template>
    <div class="result">
        <div>
            <div class="image">
                <img :src="r.image" alt="">
            </div>
            <div class="btn" @click="view()">View</div>
        </div>
        <div class="info">
            <div class="flex">
                <div class="label">Status:</div>
                <div class="value">{{r.status}}</div>
            </div>
            <div class="flex">
                <div class="label">Church Group:</div>
                <div class="value">{{r.church_group}}</div>
            </div>
            <div class="flex">
                <div class="label">Firstname:</div>
                <div class="value">{{r.firstname}}</div>
            </div>
            <div class="flex">
                <div class="label">Other Names:</div>
                <div class="value">{{r.other_names}}</div>
            </div>
            <div class="flex">
                <div class="label">Surname:</div>
                <div class="value">{{r.surname}}</div>
            </div>
            <div class="flex">
                <div class="label">Gender:</div>
                <div class="value">{{r.gender}}</div>
            </div>
            <div class="flex">
                <div class="label">Marital Status:</div>
                <div class="value">{{r.marital}}</div>
            </div>
            <div class="flex">
                <div class="label">Spouse Name:</div>
                <div class="value">{{r.spouse}}</div>
            </div>
            <div class="flex">
                <div class="label">Children:</div>
                <div class="value">{{r.children}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['r'],
    data() {
        return {
            imgurl: this.$root.$data.imgurl
        }
    },
    methods: {
        view() {
            localStorage.setItem('member', JSON.stringify(this.r))

            setTimeout(() => {
                this.$router.push('/member')
            }, 500);
        }
    }
}
</script>

<style scoped>

    .result {
        display: grid;
        grid-template-columns: max-content auto;
        padding: 20px;
        border-radius: 5px;
        background-color: white;
        grid-gap: 20px;
        border: 1px solid #ccc;
    }
    .image {
        width: 150px;
        overflow: hidden;
        border-radius: 5px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        padding: 5px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex:nth-child(even) {
        background-color: var(--trans);
        /* border-radius: 5px; */
    }
    .label {
        margin-right: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }

    @media screen and (max-width: 500px) {
        .result {
            grid-template-columns: auto;
        }
    }
</style>