<template>
    <div class="note">
        <div class="head">
            <div class="title">{{note.title}}</div>
            <div class="time">{{note.date}}</div>

            <div class="loading remove" v-if="loading">
                <div class="spin"></div>
            </div>
            <div class="remove" @click="del" v-else>
                <remove :size="'25px'"/>
            </div>
        </div>
        <div class="description">
            {{note.note}}
        </div>
    </div>
</template>

<script>
import remove from '../../icons/delete.vue'
export default {
    props: ['note'],
    components: {
        remove
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        del() {
            this.loading = true
            let form = new FormData()
            form.append('id', this.note.id)
            
            this.$http.post('/notes/delete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.deleted()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        deleted: {
            type: 'success',
            title: '',
            message: 'Note deleted'
        }
    }
}
</script>

<style scoped>
    .note {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .head {
        position: relative;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .time {
        font-size: 12px;
        color: gray;
    }
    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .remove {
        position: absolute;
        top: 5px;
        right: 0px;
        fill: red;
        cursor: pointer;
    }

    .description {
        margin-top: 20px;
        font-size: 14px;
        line-height: 23px;
    }
</style>