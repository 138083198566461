<template>
    <div class="complete">

        <div class="title">
            Pastors whose information is up to date
        </div>
        <tableLayout :pastors="members" :loading="loading"/>
    </div>
</template>

<script>
import tableLayout from '../components/table.vue'
export default {

    components: {
        tableLayout
    },
    data() {
        return {
            members: [],
            loading: false
        }
    },
    mounted() {
        this.loading = true
        let form = new FormData()
        form.append('save', 'ok')
        this.$http.post('/members', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.members = r.members
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .complete {
        padding: 30px;
    }
    .title {
        font-size: 25px;
        font-weight: 400;
    }
</style>