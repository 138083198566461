<template>
    <div class="box">
        <div class="title">
            Empty
        </div>
        <div class="text">
            You have not added any note yet
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .box {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .text {
        font-size: 14px;
    }
</style>