<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans}">
        <router-view/>
    </div>
</template>

<script>
export default {
    
    computed: {
        c() {
            return this.$root.$data.colors
        }
    },
}
</script>

<style>
#app {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
body {
    padding: 0px;
    margin: 0px;
}
a {
    color: inherit;
    text-decoration: none;
}
textarea {
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
}
</style>
