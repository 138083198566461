<template>
    <div>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            :width="size" :height="size" viewBox="0 0 18 18" style="enable-background:new 0 0 342.508 342.508;"
            xml:space="preserve">
            
            <g stroke="none" stroke-width="1">
                <g transform="translate(-465.000000, -45.000000)">
                    <g transform="translate(465.000000, 45.000000)">
                        <path d="M16,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,2 C18,0.9 17.1,0 16,0 L16,0 Z M7,14 L2,9 L3.4,7.6 L7,11.2 L14.6,3.6 L16,5 L7,14 L7,14 Z"/>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>